export const validateMobile = (value) => {
  const regExp = /^[0-9]{10}$/;
  return regExp.test(value)
    ? ""
    : "Mobile number must be 10 digits with no special characters.";
};

export const validatePinCode = (value) => {
  const regExp = /^[0-9]{6}$/;
  return regExp.test(value) ? "" : "PIN code must be exactly 6 digits.";
};
