import React from "react";
import Image from "../assets/images/therapist.png";

const TimeSlotCard = ({ time, available, users, onClick, isSelected }) => {
  const dateTime = new Date(time);

  console.log(time)
  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(':');
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    if (hours12 === 0) {
      hours12 = 12;
    }

    return `${hours12}:${minutes} ${ampm}`;
  }

  const formattedTime24 = dateTime.toTimeString().split(' ')[0].slice(0, 5);
  const formattedTime = convertTo12HourFormat(formattedTime24);

  return (
    <div
      className={`TimeSlotWrapper ${isSelected ? "TimeSlotWrapper timeSelectedCard" : "TimeSlotWrapper"
        }`}
      onClick={onClick}
    >
      <p
        className="text-center"
        style={{ fontSize: "14px", fontWeight: "400" }}
      >
        {formattedTime}
      </p>
    </div>
  );
};

export default TimeSlotCard;
