import React from "react";

const Button = ({ btnName, style, onClick, disabled }) => {
  return (
    <>
      <div>
        <button onClick={onClick} className={style} disabled={disabled}>
          {btnName}
        </button>
      </div>
    </>
  );
};

export default Button;
