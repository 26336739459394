import { useRef, useCallback } from 'react';

const useDraggableScroll = () => {
  const ref = useRef(null);
  const isDown = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const onMouseDown = useCallback((e) => {
    const element = ref.current;
    if (element) {
      isDown.current = true;
      startX.current = e.pageX - element.offsetLeft;
      scrollLeft.current = element.scrollLeft;
      element.style.cursor = 'grabbing';
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    const element = ref.current;
    if (element) {
      isDown.current = false;
      element.style.cursor = 'grab';
    }
  }, []);

  const onMouseUp = useCallback(() => {
    const element = ref.current;
    if (element) {
      isDown.current = false;
      element.style.cursor = 'grab';
    }
  }, []);

  const onMouseMove = useCallback((e) => {
    const element = ref.current;
    if (!isDown.current || !element) return;
    e.preventDefault();
    const x = e.pageX - element.offsetLeft;
    const walk = (x - startX.current) * 3; // Adjust scroll speed here
    element.scrollLeft = scrollLeft.current - walk;
  }, []);

  return { ref, onMouseDown, onMouseLeave, onMouseUp, onMouseMove };
};

export default useDraggableScroll;
