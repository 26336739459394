import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "../css/booking.css";
import TherapistCard from "../components/TherapistCard";
import Back from "../assets/images/backArrow.svg";
import DateCard from "../components/DateCard";
import TimeSlotCard from "../components/TimeSlotCard";
import instance from "../api/axios";
import {
  getAllTherapist,
  getTimeSlotOfTherapist,
  getAllTherapistTimeSlots,
  getAllTherapistAvailableTimeSlots,
} from "../api/route";
import { CircularProgress } from "@mui/joy";
import Loader from "../components/Loader";
import SessionDetail from "../components/SessionDetail/sessionDetail";
import AboutPhysica from "../components/About/aboutPhysica";
import useDraggableScroll from "../hooks";

const SlotPage = () => {
  const { ref, onMouseDown, onMouseLeave, onMouseUp, onMouseMove } =
    useDraggableScroll();

  const todayDate = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const today = new Date(todayDate);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [Therapist, setTherapists] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState();
  const [selectedAllDoctorId, setSelectedAllDoctorId] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setselectedDate] = useState(
    today.toISOString().split("T")[0]
  );
  const [allAvailable, setAllAvailable] = useState([]);
  const [showTherapistSection, setShowTherapistSection] = useState(false);

  const [morningSlots, setMorningSlots] = useState(false);
  const [noonSlots, setNoonSlots] = useState(false);
  const [eveningSlots, setEveningSlots] = useState(false);

  const [morningAllSlots, setMorningAllSlots] = useState(false);
  const [noonAllSlots, setNoonAllSlots] = useState(false);
  const [eveningAllSlots, setEveningAllSlots] = useState(false);

  const navigate = useNavigate();

  const getTherapistFromPinCode = async () => {
    setLoader(true);
    try {
      const getUser = sessionStorage.getItem("user");
      const { pinCode } = JSON.parse(getUser);
      const response = await instance.get(
        `${getAllTherapist}?pinCode=${pinCode}`
      );
      console.log("Therapist by pinCode", response.data.therapists);
      setTherapists(response.data.therapists);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const getTimeSlotTherapist = async () => {
    setLoader(true);
    try {
      console.log(selectedDate);
      const response = await instance.get(
        `${getTimeSlotOfTherapist}/${selectedDoctorId}/${selectedDate}`
      );
      console.log("time slots", response?.data?.timeSlots);

      setTimeSlots(
        response?.data?.timeSlots?.slots.reduce((acc, curr) => {
          const filteredSlots = curr.hours.filter(
            (slot) => slot.available === 1
          );
          if (filteredSlots.length > 0) {
            acc.push({
              ...curr,
              slots: filteredSlots,
            });
          }
          return acc;
        }, [])
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  console.log(timeSlots);
  const getAllTherapistTimeSlot = async () => {
    setLoader(true);
    try {
      const getUser = sessionStorage.getItem("user");
      const { pinCode } = JSON.parse(getUser);
      const response = await instance.get(
        `${getAllTherapistTimeSlots}?pinCode=${pinCode}`
      );
      console.log("all therapist time slot", response?.data?.timeSlots);

      const timing = [];
      const firstDate = Object.keys(response?.data?.timeSlots)[0];
      setselectedDate(firstDate);
      for (const [key, value] of Object.entries(response?.data?.timeSlots)) {
        const obj = {
          date: key,
          slots: value.length > 0,
        };
        timing.push(obj);
      }
      setAllTimeSlots(response?.data?.timeSlots);
      console.log("setallTimeSlots", allTimeSlots);
      setAllAvailable(timing);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const getAllAvailableTimeSlots = async () => {
    setLoader(true);
    try {
      const getUser = sessionStorage.getItem("user");
      const { pinCode } = JSON.parse(getUser);
      let res;
      if (selectedDoctorId) {
        res = await instance.get(
          `${getAllTherapistAvailableTimeSlots}?pinCode=${pinCode}&dayschedule_id=${selectedDoctorId}`
        );
      } else {
        res = await instance.get(
          `${getAllTherapistAvailableTimeSlots}?pinCode=${pinCode}`
        );
      }
      console.log("all therapist timing", res?.data?.transformedData);
      setAllAvailable(res?.data?.transformedData);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!selectedDoctorId) {
      getAllTherapistTimeSlot();
    }
  }, []);

  useEffect(() => {
    getTherapistFromPinCode();
  }, []);

  useEffect(() => {
    if (selectedDoctorId) {
      getTimeSlotTherapist();
    }
  }, [selectedDoctorId, selectedDate]);

  useEffect(() => {
    if (selectedDoctorId) {
      getAllAvailableTimeSlots();
    }
  }, [selectedDoctorId, selectedDate]);

  const handleTimeSlotSelection = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
    setSelectedAllDoctorId(timeSlot.therapist_id);
  };

  const renderAllTimeSlots = useMemo(
    () => (startTime, endTime) => {
      if (!allTimeSlots || !selectedDate) return [];

      const filteredTimeSlots = allTimeSlots[selectedDate]?.filter(
        (timeSlot) => {
          const hour = new Date(timeSlot.time).getHours();
          return hour >= startTime && hour < endTime;
        }
      );

      return filteredTimeSlots?.map((timeSlot) => {
        const isSelected = selectedTimeSlot?.time === timeSlot.time;
        return (
          <TimeSlotCard
            key={timeSlot.time}
            time={timeSlot.time}
            available={timeSlot.available}
            isSelected={isSelected}
            onClick={() => handleTimeSlotSelection(timeSlot)}
          />
        );
      });
    },
    [allTimeSlots, selectedDate, selectedTimeSlot]
  );

  const renderTimeSlots = useMemo(
    () => (startTime, endTime) => {
      return timeSlots
        .flatMap((slot) => slot.slots)
        .filter((timeSlot) => {
          const dateTime = new Date(timeSlot.time);
          const selecteddate = new Date(selectedDate);

          const hour = dateTime.getHours();
          const date = dateTime.getDate();

          return (
            date === selecteddate.getDate() &&
            hour >= startTime &&
            hour < endTime &&
            timeSlot.available === 1
          );
        })
        .map((timeSlot) => (
          <TimeSlotCard
            key={timeSlot.time}
            time={timeSlot.time}
            available={timeSlot.available}
            isSelected={selectedTimeSlot?.time === timeSlot.time}
            onClick={() => handleTimeSlotSelection(timeSlot)}
          />
        ));
    },
    [timeSlots, selectedTimeSlot]
  );

  useEffect(() => {
    setMorningSlots(
      timeSlots[0]?.slots.some((slot) => {
        const dateTime = new Date(slot.time);
        const hour = dateTime.getHours();
        return hour >= 9 && hour < 12;
      })
    );

    setNoonSlots(
      timeSlots[0]?.slots.some((slot) => {
        const dateTime = new Date(slot.time);
        const hour = dateTime.getHours();
        return hour >= 12 && hour < 16;
      })
    );

    setEveningSlots(
      timeSlots[0]?.slots.some((slot) => {
        const dateTime = new Date(slot.time);
        const hour = dateTime.getHours();
        return hour >= 16 && hour < 21;
      })
    );
  }, [timeSlots, selectedDate]);

  useEffect(() => {
    if (
      Array.isArray(allTimeSlots[selectedDate]) &&
      allTimeSlots[selectedDate]
    ) {
      console.log("allTimeSlots[selectedDate]", allTimeSlots[selectedDate]);
      setMorningAllSlots(
        allTimeSlots[selectedDate].some((timeSlot) => {
          const hour = new Date(timeSlot.time).getHours();
          return hour >= 9 && hour < 12;
        })
      );

      setNoonAllSlots(
        allTimeSlots[selectedDate].some((timeSlot) => {
          const hour = new Date(timeSlot.time).getHours();
          return hour >= 12 && hour < 16;
        })
      );

      setEveningAllSlots(
        allTimeSlots[selectedDate].some((timeSlot) => {
          const hour = new Date(timeSlot.time).getHours();
          return hour >= 16 && hour < 21;
        })
      );
    }
  }, [allAvailable, selectedDate]);

  const handleProceed = () => {
    if (selectedTimeSlot) {
      navigate("/booking-detail", {
        state: {
          therapistId: selectedDoctorId || selectedAllDoctorId,
          timing: selectedTimeSlot,
        },
      });
    }
  };

  const handleAllTherapistTime = () => {
    getAllTherapistTimeSlot();
    setSelectedDoctorId(null);
  };

  const handleChooseTherapistClick = () => {
    setShowTherapistSection(!showTherapistSection);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="neutral" size="md" variant="plain" />
      </div>
    );
  }

  return (
    <>
      <div className="BookingContainer">
        <div className="BookingWrapper">
          <div className="mt-2 cursor-pointer" onClick={handleBackButtonClick}>
            <img src={Back} alt="Back button" />
          </div>
          <div className="BookingHeading">
            <p>
              Book an appointment with <br />
              <strong> Physica Physiotherapy</strong>
            </p>
          </div>
          <AboutPhysica
            handleChooseTherapistClick={handleChooseTherapistClick}
            setShowMore={setShowMore}
            showMore={showMore}
          />
          {showTherapistSection && (
            <div className="ChooseTherapistContainer">
              <h1>Select a Therapist</h1>
              <div className="ChooseTherapistWrapper">
                <div
                  className={
                    selectedAllDoctorId ||
                    (!selectedDoctorId &&
                      allTimeSlots[selectedDate]?.length > 0)
                      ? "AllTherapist selectedCard"
                      : "AllTherapist"
                  }
                  onClick={() => handleAllTherapistTime()}
                >
                  <p>ALL</p>
                  <p>Available</p>
                </div>
                <div
                  className="TherapistCards"
                  ref={ref}
                  onMouseDown={onMouseDown}
                  onMouseLeave={onMouseLeave}
                  onMouseUp={onMouseUp}
                  onMouseMove={onMouseMove}
                >
                  {Therapist?.map(
                    (item, index) =>
                      item?.homevisit_resource_id !== "" && (
                        <TherapistCard
                          setSelectedDoctorId={setSelectedDoctorId}
                          item={item}
                          selectedDoctorId={selectedDoctorId}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          )}
          <SessionDetail />
          <div className="ChooseTherapistContainer">
            <h1>Select a Date</h1>
            <div className="TherapistCards DateCardWrapper2">
              {allAvailable?.map((slot) => (
                <DateCard
                  selectedDate={selectedDate}
                  key={slot.date}
                  data={slot}
                  setselectedDate={setselectedDate}
                  allTimeSlots={allTimeSlots}
                />
              ))}
            </div>
          </div>
          {selectedDoctorId ? (
            <div className="TimingHeading">
              {loader ? (
                <Loader />
              ) : (
                <>
                  <h1>Time</h1>
                  {morningSlots && (
                    <div className="MorningDiv">
                      <div className="MorningDivHeading">
                        <p>Morning</p>
                        <p>8:00 AM- 12:00PM</p>
                      </div>
                      <div className="TimeSlots">{renderTimeSlots(8, 12)}</div>
                    </div>
                  )}
                  {noonSlots && (
                    <div className="MorningDiv">
                      <div className="MorningDivHeading">
                        <p>Noon</p>
                        <p>12:00 PM- 4:00PM</p>
                      </div>
                      <div className="TimeSlots">{renderTimeSlots(12, 16)}</div>
                    </div>
                  )}
                  {eveningSlots && (
                    <div className="MorningDiv">
                      <div className="MorningDivHeading">
                        <p>Evening</p>
                        <p>4:00 PM- 9:00PM</p>
                      </div>
                      <div className="TimeSlots">{renderTimeSlots(16, 21)}</div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="TimingHeading">
              {loader ? (
                <Loader />
              ) : (
                <>
                  <h1>Time</h1>
                  {morningAllSlots && (
                    <div className="MorningDiv">
                      <div className="MorningDivHeading">
                        <p>Morning</p>
                        <p>8:00 AM- 12:00PM</p>
                      </div>
                      <div className="TimeSlots">
                        {renderAllTimeSlots(8, 12)}
                      </div>
                    </div>
                  )}
                  {noonAllSlots && (
                    <div className="MorningDiv">
                      <div className="MorningDivHeading">
                        <p>Noon</p>
                        <p>12:00 PM- 4:00PM</p>
                      </div>
                      <div className="TimeSlots">
                        {renderAllTimeSlots(12, 16)}
                      </div>
                    </div>
                  )}
                  {eveningAllSlots && (
                    <div className="MorningDiv">
                      <div className="MorningDivHeading">
                        <p>Evening</p>
                        <p>4:00 PM- 9:00PM</p>
                      </div>
                      <div className="TimeSlots">
                        {renderAllTimeSlots(16, 23)}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="ProcessButton">
            <button
              onClick={handleProceed}
              disabled={!selectedTimeSlot}
              style={{
                backgroundColor: !selectedTimeSlot
                  ? "rgba(165, 165, 165, 1)"
                  : "rgb(249, 115, 22)",
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlotPage;
