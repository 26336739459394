import { useEffect, useState } from "react";
import InputText from "../InputText";
import "./editForm.css";
import Button from "../Button";
import instance from "../../api/axios";
import { createUser } from "../../api/route";
import { useNavigate } from "react-router-dom";

const EditFormModel = ({ setopenEditModel }) => {

  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    city: "",
    pinCode: "",
    fees: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const userDetail = sessionStorage.getItem("user");
    const user = JSON.parse(userDetail);
    setFormData({
      user_name: user.name,
      city: user.address,
      pinCode: user.pinCode,
      user_phone: user.phone,
    });
  }, []);

  useEffect(() => {
    setButtonDisabled(
      formData.pinCode.trim() === "" ||
        formData.user_name.trim() === "" ||
        formData.user_phone.trim() === ""
    );
  }, [formData]);

  const handleSubmit = async () => {
    const obj = {
      name: formData.user_name,
      address: formData.city,
      pinCode: formData.pinCode,
      phone: formData.user_phone,
    };

    try {
      const response = await instance.post(createUser, obj);
      console.log(response.data);

      obj.fees = formData?.fees;

      sessionStorage.setItem("user", JSON.stringify(obj));
      setopenEditModel(false);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  return (
    <div className="EditFormModelContainer">
      <div className="EditFormModelWrapper">
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => setopenEditModel(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </div>
        <div className="EditFormModelHeading">
          <h3>Edit Your Information</h3>
        </div>
        <div className="EditFormModelFormWrapper">
          <div className="EditFormModelForm">
            <InputText
              value={formData.user_name}
              name="user_name"
              label="Name"
              placeholder="Enter Your Name"
              onChange={(e) => handleInputChange("user_name", e.target.value)}
            />
            <InputText
              value={formData.user_phone}
              name="user_phone"
              label="Mobile Number"
              placeholder="Enter Your Mobile Number"
              onChange={(e) => handleInputChange("user_phone", e.target.value)}
            />
            <InputText
              name="pinCode"
              value={formData.pinCode}
              label="Pin Code"
              placeholder="Enter Your Pin Code"
              onChange={(e) => handleInputChange("pinCode", e.target.value)}
            />
            {formData?.pinCode === "111111" && (
              <InputText
                name="fees"
                value={formData.fees}
                label="Fees"
                placeholder="Enter Fees"
                onChange={(e) => handleInputChange("fees", e.target.value)}
              />
            )}
          </div>
          <div className="flex justify-center mt-8">
            <Button
              onClick={handleSubmit}
              btnName="Continue"
              style={
                "text-center text-white font-semibold text-lg px-6 py-2 bg-orange-500 rounded-xl"
              }
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFormModel;
