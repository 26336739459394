import React from "react";
import FailImg from "../../assets/images/failed.svg";
import "./confirmModel.css";

const FailModel = ({ onClose, errorMessage }) => {
    return (
        <div className="confirmModelContainer p-4">
            <div className="confirmModelWrapper p-4">
                <div className="flex justify-end cursor-pointer" onClick={onClose}>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.7338 0.275313C11.3788 -0.0796357 10.8055 -0.0796357 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.62116 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313Z"
                            fill="#393938"
                        />
                    </svg>
                </div>
                <div className="flex flex-col w-full justify-center items-center text-center text-neutral-400 text-lg py-4 px-6">
                    <div className="mb-2">
                        <img src={FailImg} alt="Confirmation" />
                    </div>
                    <div className="mt-3">
                        <h1 className="text-3xl font-bold text-black">
                            Uh Oh!
                        </h1>
                        <div className="py-4">
                            <p>Your booking could not be processed.</p>
                            <p>{errorMessage}</p>
                            <p>Please try again.</p>
                        </div>
                    </div>
                    <div className="my-4">
                        <button className="bg-indigo-900 py-2 px-4 rounded-lg text-white text-lg font-semibold cursor-pointer" onClick={onClose}>Try Again</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FailModel;
