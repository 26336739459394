import React from "react";

const InputText = ({
  warnings,
  name,
  label,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <div className="mx-6 my-3 flex flex-col">
      <label
        htmlFor={name}
        style={{ fontSize: "14px", color: "#393938", fontWeight: "600" }}
        className="pl-1 text-small"
      >
        {label}
      </label>
      <input
        type="text"
        style={{ fontSize: "16px", color: "black", fontWeight: "400" }}
        className=" border-2 text-lg rounded-lg px-2 py-1 mb-2"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {warnings && <p className="px-2" style={{ color: "red" }}>{warnings}</p>}
    </div>
  );
};

export default InputText;
