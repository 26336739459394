import React from 'react'
import { CircularProgress } from "@mui/joy";

const Loader = () => {
    return (
        <>
            <div className='flex justify-center items-center'>
                <CircularProgress color="neutral" size="md" variant="plain" />
            </div>
        </>
    )
}

export default Loader
