import React, { useState } from "react";
import './Dropdown.css';

const Dropdown = ({ data, value, callback }) => {
    const [openMenu, setOpenMenu] = useState(false);

    const handleValue = (item) => {
        callback(item);
        setOpenMenu(false);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setOpenMenu(!openMenu);
    };

    return (
        <div className="dropdown  px-6 pb-2 flex flex-col">
            <label htmlFor="city" className="pl-1" style={{ fontSize: "14px", color: "#393938", fontWeight: "600" }}>City</label>
            <button onClick={handleClick} className="dropdown-toggle">
                {value || <span style={{ fontSize: "16px", color: "#8B8B8B", fontWeight: "400" }}>Enter Your City</span>}
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.5L6 6.5L11 1.5" stroke="#393938" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </button>
            {openMenu && (
                <ul className="dropdown-menu">
                    {data.map((item) => (
                        <li key={item} onClick={() => handleValue(item)}>
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
