const createUser = "/createUser";
const getTherapist = "/getTherapist";
const getTimeSlotOfTherapist = "/therapistTimeSlot"
const getAllTherapistTimeSlots = "/allTherapistTimeSlots"
const getAllTherapist = "/getAllTherapist"
const createBooking = "/createBooking"
const getAllTherapistAvailableTimeSlots = "/allTherapistAvailableTimeSlots"

export {
    createUser,
    getTherapist,
    getAllTherapist,
    getTimeSlotOfTherapist,
    getAllTherapistTimeSlots,
    createBooking,
    getAllTherapistAvailableTimeSlots
}