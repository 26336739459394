import axios from "axios";

// export const baseURL = "http://35.154.111.186:3000/api/v1";
export const baseURL = "https://api-bookings.physica.fit/api/v1"
// export const baseURL = "http://localhost:4001/api/v1";

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;