import { BrowserRouter, Route, Routes } from "react-router-dom";
import Form from "./pages/Form";
import SlotPage from "./pages/SlotPage";
import BookingDetails from "./pages/BookingDetails";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/booking-slot" element={<SlotPage />} />
        <Route path="/booking-detail" element={<BookingDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;